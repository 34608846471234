import React from "react";
import PropTypes from "prop-types";
import styles from "./BigCalloutLink.module.scss";
import { renderButton } from "utils/renderHelpers";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

const BigCalloutLink = ({ label, href, target, size, padding }) => {
  return (
    <div className={cx({ bigCallout: true, [`isPadding${padding}`]: true })}>
      {renderButton(
        label,
        href,
        cx({
          button: true,
          isBigCalloutLink: true,
          [`isSize${size}`]: true,
        }),
        { target }
      )}
    </div>
  );
};

BigCalloutLink.defaultProps = {
  target: "_self",
  size: 1,
  padding: 1,
};

BigCalloutLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  padding: PropTypes.number,
  size: PropTypes.number,
};

export default BigCalloutLink;
