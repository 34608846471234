import React, { PureComponent } from "react";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Dialog from "react-a11y-dialog";
import DialogCarousel from "components/DialogCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalEmitter from "utils/GlobalEmitter";
import JuryCarouselItemRenderer from "./JuryCarouselItemRenderer";
import Route from "route-parser";
import Section from "react-bulma-components/lib/components/section";
import classNames from "classnames/bind";
import events from "utils/events";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { navigate } from "gatsby";
import querystring from "querystring";
import { renderFluidImage } from "utils/imageHelpers";
import { renderHtml } from "utils/renderHelpers";
import slugify from "slugify";
import styles from "./Jury.module.scss";

const cx = classNames.bind(styles);

let _members = null;

const getMembers = (items) => {
  return items
    .map((item) =>
      item.juror && item.juror.document
        ? {
            slug: item.juror.document[0].slug,
            ...item.juror.document[0].data,
          }
        : null
    )
    .filter(Boolean);
};

export default class Jury extends PureComponent {
  canOpenDialog = true;

  state = {
    active: false,
    dialogShowing: false,
    dialogTitle: null,
    dialogContents: null,
  };
  canOpenDialog = true;

  state = {
    active: false,
    dialogShowing: false,
    dialogTitle: null,
    dialogContents: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (typeof window === "undefined") {
      return null;
    }
    if (!props.location) {
      return null;
    }
    const prefix = props.dialogPrefix || `jury`;
    const routeParser = new Route(`/${props.slug}/${prefix}/:person(/)`);
    const route = routeParser.match(props.location.pathname);
    const { person } = route;
    const members = getMembers(props.items);

    const matches = members.filter(
      (p) =>
        slugify(`${p.first_name.text} ${p.last_name.text}`).toLowerCase() ===
        person
    );
    if (matches.length > 0) {
      let match = matches[0];
      let url = slugify(
        `${match.first_name.text} ${match.last_name.text}`
      ).toLowerCase();

      state = {
        dialogShowing: true,
        dialogTitle: `${match.first_name.text} ${match.last_name.text}`,
        dialogContents: (
          <DialogCarousel
            items={members.map((item, idx) => {
              return (
                <JuryCarouselItemRenderer
                  key={`${prefix}-${idx}`}
                  slug={members.slug}
                  {...item}
                />
              );
            })}
            urls={members.map(
              ({ first_name, last_name }) =>
                `${prefix}/${slugify(
                  `${first_name.text.toLowerCase()} ${last_name.text.toLowerCase()}`
                )}`
            )}
            url={`${prefix}/${url}`}
            baseUrl={`/${props.slug}`}
          />
        ),
      };
      return state;
    }
    return { dialogShowing: false };
  }

  checkQueryString() {
    if (!this.props.location) {
      return;
    }
    const qs = querystring.parse(this.props.location.search.substr(1));
    if (qs && qs.hasOwnProperty("jury")) {
      let key = Object.keys(qs)[0];
      setTimeout(() => {
        navigate(`/${this.props.slug}/${key}/${qs[key]}/`, {
          state: { modal: true },
        });
      }, 1000);
    }
  }

  onResize = () => {
    if (this.state.dialogShowing) {
      this.positionDialog();
    }
  };

  positionDialog() {
    // if (this.state.dialogContents) {
    //   let dialog = this.dialog ? this.dialog.dialog : null;
    //   if (!dialog) {
    //     return;
    //   }
    //   clearTimeout(this.dialogTimeout);
    //   this.dialogTimeout = setTimeout(() => {
    //     let width = dialog.getBoundingClientRect().width;
    //     let height = dialog.getBoundingClientRect().height;
    //     dialog.style.top = `${Math.round(
    //       window.innerHeight * 0.5 - height * 0.5
    //     )}px`;
    //     dialog.style.left = `${Math.round(
    //       window.innerWidth * 0.5 - width * 0.5
    //     )}px`;
    //   }, 0);
    // }
  }

  hideDialog = (e) => {
    document.documentElement.style.overflow = "auto";
    if (this.dialog) {
      try {
        this.dialog.off("hide", this.hideDialog);
      } catch (e) {}
      this.dialog.dialog.parentNode
        .querySelector(".dialog-overlay")
        .removeEventListener("click", this.hideDialog);
    }
    this.isDialogShowing = false;
    if (!this.canOpenDialog || !this.dialog) {
      return;
    }
    navigate(`/${this.props.slug}/`, { state: { modal: true } });
  };

  onShowDialog = () => {
    document.documentElement.style.overflow = "hidden";
  };

  checkDialog = () => {
    if (!this.canOpenDialog) {
      return;
    }
    if (this.dialog) {
      this.dialog.on("show", this.onShowDialog);
    }
    if (this.dialog && this.state.dialogShowing) {
      this.positionDialog();
      this.dialog.dialog.parentNode
        .querySelector(".dialog-overlay")
        .addEventListener("click", this.hideDialog);
      this.dialog.show();
      this.dialog.on("hide", this.hideDialog);
      this.isDialogShowing = true;
      clearTimeout(this.dialogTimeout);
      this.dialogTimeout = setTimeout(() => {
        this.positionDialog();
      }, 1);
    } else if (
      this.dialog &&
      !this.state.dialogShowing &&
      this.isDialogShowing
    ) {
      this.isDialogShowing = false;
      this.dialog.hide();
    }
  };

  componentDidMount() {
    this.checkQueryString();
    clearTimeout(this.dialogTimeout);
    this.checkDialog();
    this.dialogTimeout = setTimeout(() => {
      GlobalEmitter.on(events.resize, this.onResize);
      this.checkDialog();
    }, 750);
  }

  componentWillUnmount() {
    _members = null;
    this.canOpenDialog = false;

    if (this.dialog) {
      try {
        this.dialog.off("hide", this.hideDialog);
      } catch (e) {}
    }

    clearTimeout(this.dialogTimeout);
    GlobalEmitter.off(events.resize, this.onResize);
  }

  componentDidUpdate() {
    this.checkDialog();
  }

  render() {
    let { primary, slug, awardType, items, isSelectionCommittee } = this.props;
    const { dialogTitle, dialogContents } = this.state;
    const { description } = primary;
    items = items
      .filter((item) => item.juror && item.juror.document)
      .filter(Boolean);

    if (!items.length) {
      return null;
    }

    return (
      <React.Fragment>
        <Section
          className={`${primary.theme || `black`} ${cx({
            section: true,
            [primary.theme]: true,
          })}`}
        >
          <Container>
            <Columns mobile multiline>
              <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                <h4>{isSelectionCommittee ? `Selection Committee` : `Jury`}</h4>
              </Columns.Column>
              {awardType === "single" &&
                description &&
                description.text &&
                description.text.length > 0 && (
                  <Columns.Column
                    mobile={{ size: 12 }}
                    tablet={{ size: 3 }}
                    className={`is-flex`}
                  >
                    {renderHtml(description, `div`, styles.description)}
                  </Columns.Column>
                )}
              {items.map(({ juror }, idx) => {
                if (!juror) {
                  return null;
                }
                const data = juror.document[0].data;
                const { first_name, last_name, image } = data;
                return (
                  <Columns.Column
                    mobile={{ size: 6 }}
                    tablet={{ size: 3 }}
                    key={`jury-${idx}`}
                  >
                    <a
                      tabIndex={0}
                      href={`#modal`}
                      className={styles.card}
                      // to={`/${slug}/jury/${slugify(
                      //   `${first_name.text.toLowerCase()} ${last_name.text.toLowerCase()}`
                      // )}`}
                      onClick={() => {
                        navigate(
                          `/${slug}/jury/${slugify(
                            `${first_name.text.toLowerCase()} ${last_name.text.toLowerCase()}`
                          )}`
                        );
                      }}
                    >
                      <h3>{`${first_name.text} ${last_name.text}`}</h3>
                      <figure>
                        {renderFluidImage(image.carousel || image)}
                      </figure>
                      <div className={styles.moreLink}>
                        <span>See bio</span>
                        <i className="icon">
                          <FontAwesomeIcon icon={faPlus} />
                        </i>
                      </div>
                    </a>
                  </Columns.Column>
                );
              })}
            </Columns>
          </Container>
        </Section>
        <Dialog
          id="staff-listing-dialog"
          appRoot="#__gatsby"
          dialogRoot="#dialog-root"
          dialogRef={(dialog) => (this.dialog = dialog)}
          title={dialogTitle || ``}
          closeButtonContent={
            <i className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </i>
          }
          classNames={{
            element: "dialog-content",
            overlay: "dialog-overlay",
            base: "dialog",
            closeButton: "dialog-close",
            title: "is-sr-only",
          }}
        >
          {dialogContents || ``}
        </Dialog>
      </React.Fragment>
    );
  }
}
