import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { Link } from "gatsby";
import React from "react";
import { renderFluidImage } from "utils/imageHelpers";
import { renderHtml } from "utils/renderHelpers";
import styles from "./JuryCarouselItemRenderer.module.scss";

export default ({
  first_name,
  last_name,
  image,
  bio,
  slug,
  show_author_link,
  programs,
}) => {
  let selectedByAuthor = null;
  let citation = null;
  const risingStarsProgram = programs
    ? programs.filter(
        (p) =>
          p.program && p.program.raw && p.program.raw.uid === "rising-stars"
      )[0]
    : null;
  const mentorshipProgram = programs
    ? programs.filter(
        (p) =>
          p.program &&
          p.program.raw &&
          p.program.raw.uid === "writers-trust-mentorship"
      )[0]
    : null;
  if (risingStarsProgram) {
    selectedByAuthor =
      risingStarsProgram.selected_by && risingStarsProgram.selected_by.document
        ? risingStarsProgram.selected_by.document[0]
        : null;
    citation = risingStarsProgram.citation;
  }
  if (mentorshipProgram) {
    selectedByAuthor =
      mentorshipProgram.selected_by && mentorshipProgram.selected_by.document
        ? mentorshipProgram.selected_by.document[0]
        : null;
    citation = selectedByAuthor ? mentorshipProgram.citation : null;
  }
  const showAuthorLink = show_author_link;
  return (
    <Container className={styles.main}>
      <Columns mobile centered>
        <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
          <Columns mobile multiline>
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              <h2
                style={{ maxWidth: `40%`, lineHeight: 1.2 }}
              >{`${first_name.text} ${last_name.text}`}</h2>
            </Columns.Column>
            <Columns.Column
              mobile={{ size: 12 }}
              tablet={{ size: 6 }}
              style={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
              }}
            >
              <h6 className="has-text-weight-bold" style={{ marginBottom: 5 }}>
                Biography
              </h6>
              {renderHtml(bio, "div")}
              {citation && (
                <React.Fragment>
                  <h6
                    className="has-text-weight-bold"
                    style={{ marginBottom: 5 }}
                  >
                    Citation
                  </h6>
                  {renderHtml(citation, "div")}
                </React.Fragment>
              )}
              {showAuthorLink && (
                <Link
                  to={`/${slug}`}
                  title={`${first_name.text} ${last_name.text}`}
                  className={styles.button}
                >
                  Author page
                </Link>
              )}
            </Columns.Column>
            <Columns.Column
              mobile={{ size: 12 }}
              tablet={{ size: 5, offset: 1 }}
              style={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
              }}
            >
              <figure style={{ width: 275, display: `inline-block` }}>
                {renderFluidImage(image.carousel || image)}
              </figure>
            </Columns.Column>
            {selectedByAuthor && (
              <React.Fragment>
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: 12 }}
                  style={{ marginTop: `4rem` }}
                >
                  <h5
                    className="has-text-weight-bold"
                    style={{ marginBottom: 5 }}
                  >
                    {mentorshipProgram ? `Mentor` : `Selected By`}
                  </h5>
                  <h2
                    style={{
                      maxWidth: `40%`,
                      lineHeight: 1.2,
                    }}
                  >{`${selectedByAuthor.data.first_name.text} ${selectedByAuthor.data.last_name.text}`}</h2>
                </Columns.Column>
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: 6 }}
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                  }}
                >
                  <h6
                    className="has-text-weight-bold"
                    style={{ marginBottom: 5 }}
                  >
                    Biography
                  </h6>
                  {renderHtml(selectedByAuthor.data.bio, "div")}
                  <Link
                    to={`/${selectedByAuthor.slug}`}
                    title={`${selectedByAuthor.data.first_name.text} ${selectedByAuthor.data.last_name.text}`}
                    className={styles.button}
                  >
                    Author page
                  </Link>
                </Columns.Column>
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: 5, offset: 1 }}
                  style={{
                    display: `flex`,
                    alignItems: `center`,
                    justifyContent: `center`,
                  }}
                >
                  <figure style={{ width: 275, display: `inline-block` }}>
                    {renderFluidImage(
                      selectedByAuthor.data.image.carousel ||
                        selectedByAuthor.data.image
                    )}
                  </figure>
                </Columns.Column>
              </React.Fragment>
            )}
          </Columns>
        </Columns.Column>
      </Columns>
    </Container>
  );
};
