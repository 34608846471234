import React, { Component } from "react";
import { Link } from "gatsby";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { renderText } from "utils/renderHelpers";
import { renderFluidImage } from "utils/imageHelpers";

import BigCalloutLink from "components/BigCalloutLink";
import BreakpointListener from "components/BreakpointListener";
import PropTypes from "prop-types";
import events from "utils/events";
import classNames from "classnames/bind";
import styles from "./WritersBooksCarousel.module.scss";

const cx = classNames.bind(styles);

const renderItem = (
  {
    id,
    slug,
    image,
    title,
    first_name,
    last_name,
    publish_date,
    description,
    showDate,
    short_story
  },
  idx
) => {
  return (
    <Columns.Column className={styles.carouselColumn} key={`${id}-${idx}`}>
      <article
        className={cx({
          carouselItem: true,
          hasDate: showDate && publish_date ? true : false
        })}
      >
        <Link
          to={`/${slug}`}
          title={
            title && title.text
              ? title.text
              : `${first_name.text} ${last_name.text}`
          }
        >
          {title && title.text ? (
            renderText(
              title,
              "h3",
              null,
              {},
              null,
              null,
              null,
              null,
              null,
              true,
              short_story === "true"
            )
          ) : (
            <h3>{`${first_name.text} ${last_name.text}`}</h3>
          )}
          {image && (image.carousel || image.url) && (
            <figure className="image">
              {renderFluidImage(image.carousel, null, {
                maxWidth: [175, 175, 175, 175, 350]
              })}
            </figure>
          )}
        </Link>
        {showDate ? (
          publish_date ? (
            <span className={styles.date}>({publish_date.substr(0, 4)})</span>
          ) : null
        ) : null}
      </article>
    </Columns.Column>
  );
};

export default class WritersBooksCarousel extends Component {
  static defaultProps = {
    showDate: false,
    items: [],
    itemsShowing: {
      mobile: 1,
      tablet: 3,
      desktop: 4,
      widescreen: 5,
      fullhd: 6
    },
    gap: 22.5
  };

  static propTypes = {
    showDate: PropTypes.bool,
    primary: PropTypes.shape({
      heading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ text: PropTypes.string })
      ]),
      button_label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ text: PropTypes.string })
      ]),
      button_link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ url: PropTypes.string })
      ])
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        item: PropTypes.shape({
          document: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              image: PropTypes.object,
              title: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({ url: PropTypes.string })
              ]),
              description: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({ url: PropTypes.string })
              ])
            })
          )
        })
      })
    ).isRequired,
    id: PropTypes.string.isRequired,
    gap: PropTypes.number.isRequired,
    itemsShowing: PropTypes.shape({
      mobile: PropTypes.number,
      tablet: PropTypes.number,
      desktop: PropTypes.number,
      widescreen: PropTypes.number,
      fullhd: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      itemsShowing: this.props.itemsShowing[BreakpointListener.size]
    };
  }

  componentDidMount() {
    BreakpointListener.on(events.breakpoint, this.handleBreakpoint);

    let items = this.props.items;
    if (this.props.primary.random === "yes") {
      items.sort((a, b) => {
        let rnd = Math.random();
        if (rnd > 0.5) return 1;
        if (rnd < 0.5) return -1;
        return 0;
      });
    }
    this.setState({ items });
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.handleBreakpoint);
  }

  handleBreakpoint = ({ newSize }) => {
    let itemsShowing = this.props.itemsShowing[newSize];
    let currentIndex = this.state.currentIndex;

    // make sure we always have at least the "itemsShowing" amount in view
    // (don't allow "overscroll")
    while (this.props.items.length - currentIndex - itemsShowing < 0) {
      currentIndex--;
    }
    this.setState({
      currentIndex,
      itemsShowing
    });
  };

  nextSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex++;
    if (currentIndex >= this.props.items.length) {
      return;
    }
    this.setState({ currentIndex });
  };

  prevSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex--;
    if (currentIndex < 0) {
      return;
    }
    this.setState({ currentIndex });
  };

  getWidth() {
    const { gap } = BreakpointListener;
    const { itemsShowing } = this.state;
    const w = itemsShowing * (174 + gap) - gap + 12; // alllows for book shadow
    return w;
  }

  getPosition() {
    const { gap } = BreakpointListener;
    const { currentIndex } = this.state;
    const pos = Math.round(currentIndex * (174 + gap));
    return pos;
  }

  componentDidUpdate() {
    if (
      this.props.itemsShowing[BreakpointListener.size] !==
      this.state.itemsShowing
    ) {
      this.setState({
        itemsShowing: this.props.itemsShowing[BreakpointListener.size]
      });
    }
  }

  render() {
    const { id, primary, showDate } = this.props;
    const carouselId = `${id}-carousel`;
    const theme = primary.theme || `white`;
    const items = this.state.items || this.props.items;

    return (
      <Section
        className={`${theme} ${cx({ section: true, [theme]: true })}`}
        key={id}
      >
        <Container>{renderText(primary.heading, "h2")}</Container>
        <Container className={styles.carousel}>
          <fieldset
            className={styles.controls}
            aria-label="carousel buttons"
            aria-controls={carouselId}
          >
            <button
              aria-label="previous"
              type="button"
              onClick={this.prevSlide}
              className={cx({
                carouselButton: true,
                disabled: this.state.currentIndex === 0
              })}
            >
              <i className="icon" />
              Previous
            </button>
            <button
              aria-label="next"
              type="button"
              onClick={this.nextSlide}
              className={cx({
                carouselButton: true,
                disabled:
                  this.state.currentIndex >=
                  items.length - this.state.itemsShowing
              })}
            >
              <i className="icon" />
              Next
            </button>
          </fieldset>
          <div
            id={carouselId}
            arial-live="polite"
            className={styles.track}
            style={{ width: `${this.getWidth()}px` }}
          >
            <Columns
              mobile
              className={styles.carouselColumns}
              style={{
                transform: `translate3d(-${this.getPosition()}px ,0, 0)`
              }}
            >
              {items.map((item, idx) => {
                let doc = null;
                let slug;
                if (!item.item) {
                  return null;
                }
                if (item.item) {
                  doc = item.item.document[0];
                  if (doc.type === "PrismicWriter") {
                    slug = doc.slug;
                  } else if (doc.type === "PrismicBook" || doc.authorSlug) {
                    slug = doc.authorSlug;
                  }
                }
                return renderItem(
                  {
                    showDate,
                    id: doc.id,
                    type: doc.type,
                    slug,
                    ...doc.data
                  },
                  idx
                );
              })}
            </Columns>
          </div>
        </Container>
        {primary.button_label && (
          <Container>
            <BigCalloutLink
              href="/writers-books"
              label={primary.button_label}
              size={2}
              padding={2}
            />
          </Container>
        )}
      </Section>
    );
  }
}
